export default {
    'posUrl': 'https://us-central1-df-kirin.cloudfunctions.net/pos',
    'merchantId': 'jseven',
    'businessName': 'J7',
    'businessUrl': 'https://piticafe.piticommerce.com',
    'defaultPhoto': 'https://firebasestorage.googleapis.com/v0/b/pitiapp-staging.appspot.com/o/photos%2Fnophoto.jpg?alt=media&token=75655357-4ee1-43bb-aac8-a9f14d5c4bc3',
    'cookieUserKey': 'cookieUserKey',
    'cookieCartItemKey': 'cookieCartItemKey',
    'cookieShippingKey': 'cookieShippingKey',
    'db': {
        'name': 'BrowserStorage',
        'storeName': 'JsevenStorage',
        'description': 'Jseven localstorage'
    },
    "contact": {
        "address": 'No.143. Corner of Sateta Thukha St & Bayintnaung Rd, Insein, Yangon, Myanmar (Burma)',
        "phone": ' 09 2540 21066',
    },
    'shipping': {
        // 'Yangon': 'Free',
        // 'Mandalay': '3000MMK'
    },
    'bankAccount': {
        'AYA': 'xxx xxxxx xxxx',
        'KBZ': 'xxx xxxxx xxxx',
    }
}
export const pro = {
    apiKey: "AIzaSyBnfir9kTzHTvgK5tMF8Sy_auc2yBm-Cc8",
    authDomain: "df-kirin.firebaseapp.com",
    databaseURL: "https://df-kirin.firebaseio.com",
    projectId: "df-kirin",
    storageBucket: "df-kirin.appspot.com",
    messagingSenderId: "816384609076",
    appId: "1:816384609076:web:51f5040bcfdb16fca0f361",
}
export const stag = {
    apiKey: "AIzaSyAxQjZH660AoyvBtllcHtMKV2UQuYlcKEY",
    authDomain: "pitiapp-staging.firebaseapp.com",
    databaseURL: "https://pitiapp-staging.firebaseio.com",
    projectId: "pitiapp-staging",
    storageBucket: "pitiapp-staging.appspot.com",
    messagingSenderId: "189699074584",
    appId: "1:189699074584:web:1e5ad93836fffe63c3716d",
    measurementId: "G-ENQ8V8XG3E"
}